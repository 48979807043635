// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-bot-program-container {
    position: absolute;
    width: 100%;
    height: 100%;
}

.main-bot-program-bot-container {
    position: absolute;
    width: 60px;
    height: 60px;
    transform: translate(-50%, -50%);
}

.main-bot-program-button {
    position: absolute;
    width: 400px;
    height: 400px;
    top: 50%;
    left: 50%;
    /* background-color: lightblue; */
    border-radius: 50%;
    clip-path: polygon(50% 50%, 100% 50%, 100% 0%, 50% 50%);
}


@keyframes programImageFrames {
    0% { width: 200%; height: 200%; }
    100% { width: 100%; height: 100%; }
}

.main-bot-program-normal-container{
    position: absolute;
    width: 100%;
    height: 100%;
}

.main-bot-program-animation-container {
    position: absolute;
    width: 100%;
    height: 100%;
    animation: programImageFrames 0.5s linear;
}

.main-bot-program-image {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-size: cover;
    -webkit-user-select: none;
            user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/games/components/MainMenuProgram.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,gCAAgC;AACpC;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,QAAQ;IACR,SAAS;IACT,iCAAiC;IACjC,kBAAkB;IAClB,uDAAuD;AAC3D;;;AAGA;IACI,KAAK,WAAW,EAAE,YAAY,EAAE;IAChC,OAAO,WAAW,EAAE,YAAY,EAAE;AACtC;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,yCAAyC;AAC7C;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,sBAAsB;IACtB,yBAAiB;YAAjB,iBAAiB;AACrB","sourcesContent":[".main-bot-program-container {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n}\n\n.main-bot-program-bot-container {\n    position: absolute;\n    width: 60px;\n    height: 60px;\n    transform: translate(-50%, -50%);\n}\n\n.main-bot-program-button {\n    position: absolute;\n    width: 400px;\n    height: 400px;\n    top: 50%;\n    left: 50%;\n    /* background-color: lightblue; */\n    border-radius: 50%;\n    clip-path: polygon(50% 50%, 100% 50%, 100% 0%, 50% 50%);\n}\n\n\n@keyframes programImageFrames {\n    0% { width: 200%; height: 200%; }\n    100% { width: 100%; height: 100%; }\n}\n\n.main-bot-program-normal-container{\n    position: absolute;\n    width: 100%;\n    height: 100%;\n}\n\n.main-bot-program-animation-container {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    animation: programImageFrames 0.5s linear;\n}\n\n.main-bot-program-image {\n    position: absolute;\n    width: 100%;\n    height: 100%;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    background-size: cover;\n    user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
